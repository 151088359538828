const vue_route_fix_reg = /[`^\[\]{}\|"<> ]|%25(?!25)/g
import { PICKS_PAGE_PATH_REG } from 'public/src/pages/product_app/router/constants.js'

function pathCheck(to) {
  const fixed = to.path.replace(vue_route_fix_reg, _ => `${encodeURIComponent(_)}`)
  if (fixed !== to.path) {
    return to.fullPath.replace(to.path, fixed)
  }
}

function getListTypeAndRouteIdByRoute(path) {
  if (!path) return {}
  let match = path.match(/-c-(\d+)\.html$/)
  if (match) {
    return {
      type: 'entity',
    }
  }

  match = path.match(/((\w+)\/)?[^/]+-sc-([A-Za-z0-9]+)\.html$/)
  if (match) {
    return {
      type: 'selection',
    }
  }

  match = path.match(/sellingpoint-\d+-(\d+)-.+\.html$/)
  if (match) {
    return {
      type: 'selling',
    }
  }

  match = path.match(/pdsearch\/([^\/]+)\/?$/)
  if (match) {
    return {
      type: 'search',
    }
  }

  match = path.match(/daily-new\.html$/)
  if (match) {
    return {
      type: 'daily_new',
    }
  }

  match = path.match(PICKS_PAGE_PATH_REG)
  if (match) {
    return {
      type: 'picks'
    }
  }

  return {}
}

export default {
  beforeRouteEnter(to, from, next) {
    if (typeof window === 'undefined') return next()
    const url = pathCheck(to)
    Object.assign(to.meta, getListTypeAndRouteIdByRoute(to.path)) // 补充type
    // url ? Vue.prototype.$routerReplace(url) : next()
    url ? window.$routerReplace(url) : next() // @todo [x] 待测试
  },
  beforeRouteUpdate(to, from, next) {
    const url = pathCheck(to)
    Object.assign(to.meta, getListTypeAndRouteIdByRoute(to.path)) // 补充type
    if (url) {
      // return window.appRouteExtData.direction === 0
      //   ? Vue.prototype.$routerPush(url)
      //   : Vue.prototype.$routerReplace(url)
      return window.appRouteExtData.direction === 0
        ? window.$routerPush(url) // @todo [x] 待测试
        : window.$routerReplace(url) // @todo [x] 待测试
    }
    if (to.meta.ignoreScrollBehavior) to.meta.ignoreScrollBehavior.status = true
    next()
  },
  beforeRouteLeave(to, from, next) {
    // 处理半屏弹窗点击跳转再返回列表时被置顶的问题
    from.meta.savedPosition = { left: 0, top: window.scrollY || Math.abs(parseInt(window.document?.body?.style?.top)) }
    next()
  },
}

